<script lang="ts" setup>
import GBaseIcon from '../../base/GBaseIcon.vue';
import { ref } from 'vue';

type Props = {
  image?: string;
  maximumSize?: number;
  allowedFiles?: string[];
  isLoadingUpload?: boolean;
  shopId: string;
};

const emit = defineEmits<{
  (e: 'uploadImage', formData: FormData): void;
  (e: 'deleteImage'): void;
}>();

const props = withDefaults(defineProps<Props>(), {
  image: '',
  maximumSize: 1 * 1024 * 1024, // Byte
  allowedFiles: () => ['.jpg', '.jpeg', '.gif', '.png', '.webp', '.ico'],
});

const warning = ref('');
const faviconImage = ref<HTMLInputElement>();

const allowedFilesRegex = (allowedFiles: string[]) => {
  return new RegExp(allowedFiles.join('|') + '$');
};

const handleFile = (event: any) => {
  if (event.target?.files?.length) {
    const [file] = event.target.files;
    const regex = allowedFilesRegex(props.allowedFiles);
    if (regex.test(file.name.toLowerCase())) {
      if (file.size < props.maximumSize) {
        postUploadImage(file);
      } else {
        warning.value = 'size';
      }
    } else {
      warning.value = 'format';
    }
    event.target.value = '';
  }
};

const postUploadImage = (urlImage: string) => {
  const formData = new FormData();
  formData.append('file', urlImage);
  formData.append('shopID', props.shopId);
  formData.append('appName', 'gemx');
  formData.append('fileType', 'GALLERY');
  formData.append('storage', 'SHOPIFY');

  warning.value = '';
  emit('uploadImage', formData);
};

const handleDelete = () => {
  emit('deleteImage');
};
</script>

<template>
  <div class="rounded-medium text-dark-400 w-full bg-white p-16">
    <div class="flex items-end justify-between">
      <span class="font-medium">Favicon Preview</span>
      <span class="text-12 text-text-light-100">(Recommended size: 44px x 44px)</span>
    </div>

    <hr class="bg-dark-200 my-16" />
    <div class="inline-flex flex-row">
      <div>
        <img v-if="image && !isLoadingUpload" :src="image" alt="image favicon" class="h-44 w-44 object-cover" />
        <div v-if="isLoadingUpload" class="z-50 flex h-full w-full items-center justify-center bg-transparent">
          <div
            class="custom-control-loading-icon h-24 w-24 animate-spin rounded-full border-2 border-solid border-t-transparent"></div>
        </div>
      </div>
      <div class="rounded-medium relative mx-10 flex items-center border border-solid bg-white py-8 px-24">
        <span class="text-dark-400 mr-8 inline-flex">
          <g-base-icon name="general-upload" viewBox="0 0 14 14" width="14" height="14"></g-base-icon>
        </span>
        <span class="text-14 select-none">Upload Favicon</span>
        <label for="file-upload-favicon" class="absolute inset-0 cursor-pointer"></label>
      </div>
      <GButtonV2 v-if="image" type="danger" @click="handleDelete">Delete</GButtonV2>
      <div>
        <input
          id="file-upload-favicon"
          ref="faviconImage"
          name="favicon"
          type="file"
          accept="image/*"
          class="hidden"
          @change="handleFile" />
      </div>
    </div>

    <div v-if="warning" class="min-h-[65px] p-8">
      <div class="mb-8 flex gap-8">
        <span class="text-yellow-400">
          <g-base-icon name="Warning" viewBox="0 0 18 16" width="18" height="16"></g-base-icon>
        </span>
        <span v-if="warning === 'size'" class="text-14 text-yellow-400">
          Your image exceeds maximum file size (1MB). Please crop or compress the image to reduce file size before
          upload again.
        </span>
        <span v-else-if="warning === 'format'" class="text-14 text-yellow-400">
          Image format not supported. Please upload images in JPG, JPEG, GIF, PNG, WEBP, ICO formats.
        </span>
      </div>
    </div>
  </div>
</template>
